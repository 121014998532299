// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_screen_container__FnGcD {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100vw;
  height: 100vh;
}
.PageNotFound_screen_container__FnGcD h3 {
  padding-right: 16px;
  border-right: 3px solid #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/side/guest/not-found/PageNotFound.screen.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EAEA,YAAA;EACA,aAAA;AADF;AAGE;EACE,mBAAA;EACA,+BAAA;AADJ","sourcesContent":["@use \"../../../assets/scss/main.scss\" as *;\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n\n  width: 100vw;\n  height: 100vh;\n\n  h3 {\n    padding-right: 16px;\n    border-right: 3px solid $white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PageNotFound_screen_container__FnGcD`
};
export default ___CSS_LOADER_EXPORT___;
