import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_COUPON } from "./CouponsProvider.consts";

// schemas
import { allCouponsSchema, couponSchema } from "./CouponsProvider.schemas";

// types
import type {
  CouponContext,
  CouponFormType,
  CouponProviderProps,
  CouponType,
} from "./CouponsProvider.types";

export const couponContext = React.createContext({} as CouponContext);

export const CouponProvider: FunctionComponent<CouponProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [couponsData, setCouponsData] = useState<CouponType[] | null>(null);
  const [couponById, setCouponById] = useState<CouponType | null>(null);

  const getCoupons = async () => {
    try {
      const response = await api(API_URL_COUPON, {}, allCouponsSchema);

      if (response) {
        const allCoupons = response.data;

        setCouponsData(allCoupons);
        return allCoupons;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getCouponById = async (couponId: string) => {
    try {
      const response = await api(
        `${API_URL_COUPON}/${couponId}`,
        {},
        couponSchema
      );

      if (response) {
        const currentCoupon = response;

        setCouponById(currentCoupon);
        return currentCoupon;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const addCoupon = async (formData: CouponFormType) => {
    try {
      if (couponsData) {
        const addedCoupon = await api(
          API_URL_COUPON,
          {
            method: "POST",
            data: formData,
          },
          couponSchema
        );

        if (addedCoupon) {
          const updatedCoupons = [addedCoupon, ...couponsData];

          setCouponsData(updatedCoupons);
          return updatedCoupons;
        }
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const updateCoupon = async (couponId: string, formData: CouponFormType) => {
    try {
      if (couponsData) {
        await api(`${API_URL_COUPON}/${couponId}`, {
          method: "PUT",
          data: formData,
        });

        const updatedCoupons = couponsData.map((coupon) => {
          if (coupon.id === couponId) {
            return { ...coupon, ...formData };
          }
          return coupon;
        });

        setCouponsData(updatedCoupons);
        return updatedCoupons;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const deleteCoupon = async (couponId: string) => {
    try {
      if (couponsData) {
        await api(`${API_URL_COUPON}/${couponId}`, {
          method: "DELETE",
        });
        const updatedCoupons = couponsData.filter(
          (coupon) => coupon.id !== couponId
        );

        setCouponsData(updatedCoupons);
        return updatedCoupons;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const applyCoupon = async (couponId: string) => {
    try {
      await api(`${API_URL_COUPON}/${couponId}/use`, {
        method: "PUT",
      });
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      getCoupons,
      getCouponById,
      addCoupon,
      updateCoupon,
      deleteCoupon,
      applyCoupon,
      couponsData,
      couponById,
    }),
    [
      getCoupons,
      getCouponById,
      addCoupon,
      updateCoupon,
      deleteCoupon,
      applyCoupon,
      couponsData,
      couponById,
    ]
  );

  return (
    <couponContext.Provider value={contextValue}>
      {children}
    </couponContext.Provider>
  );
};
