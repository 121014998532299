import React, { useContext } from "react";

// carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// components
import CarouselItem from "./carousel-item/CarouselItem";

// consts
import { introductionItemsData } from "./Introduction.consts";

// styles
import styles from "./Introduction.module.scss";

export function Introduction() {
  return (
    <section className={styles.introductionSection}>
      <div className={styles.contentWrapper}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay
        >
          {introductionItemsData.map((introductionItem, index) => (
            <CarouselItem key={index} introductionItem={introductionItem} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}
