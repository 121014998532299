import React, { useCallback, useContext } from "react";

// components
import Button from "../../button/Button";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";

// styles
import styles from "./CartModalOrder.module.scss";

type CartModalOrderProps = {
  items: number;
  total: string;
  idsWithQuantity: string[];
  collectionsId: string[];
};

export function CartModalOrder({
  items,
  total,
  idsWithQuantity,
  collectionsId,
}: CartModalOrderProps) {
  const { error } = useContext(errorContext);
  const { createOrder } = useContext(orderContext);
  const { checkoutPayment } = useContext(paymentContext);

  const handleCheckout = useCallback(async () => {
    try {
      const orderData = await createOrder(idsWithQuantity, collectionsId);

      if (orderData) {
        await checkoutPayment(orderData.id);
      }
    } catch (e) {
      error(e);
    }
  }, [checkoutPayment, total]);

  return (
    <div className={styles.orderWrapper}>
      <div>
        <h6>Order Summary</h6>
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <p>Subtotal ({items} items):</p>
          <p>€{total}</p>
        </div>
        <div className={styles.total}>
          <p>Total</p>
          <p>€{total}</p>
        </div>
        <div className={styles.separator} />
        <div>
          <Button onClick={handleCheckout}>Secure Checkout</Button>
        </div>
      </div>
    </div>
  );
}
