import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { couponContext } from "../../../context/coupon-provider/CouponProvider";

// consts
import { INIT_COUPONS_FORM } from "./CouponsAdmin.consts";

// types
import type {
  CouponFormType,
  CouponType,
} from "../../../context/coupon-provider/CouponsProvider.types";

export function useCouponAdminForm(
  modalType?: "create" | "edit" | null,
  coupon?: CouponType | null
) {
  const { error, success } = useContext(errorContext);
  const { addCoupon, updateCoupon, deleteCoupon } = useContext(couponContext);

  const [couponFormData, setCouponFormData] =
    useState<CouponFormType>(INIT_COUPONS_FORM);

  useEffect(() => {
    if (coupon && modalType === "edit") {
      const { code, description, discount, isActivated } = coupon;

      setCouponFormData({ code, description, discount, isActivated });
    } else {
      setCouponFormData(INIT_COUPONS_FORM);
    }
  }, [coupon, modalType]);

  const handleChangeCouponsData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      setCouponFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setCouponFormData]
  );

  const handleRemoveCoupon = useCallback(
    async (couponId?: string) => {
      try {
        if (couponId) {
          await deleteCoupon(couponId);
        }

        success("The coupon has been successfully deleted.");
      } catch (e) {
        throw e;
      }
    },
    [deleteCoupon, success, error]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        // edit
        if (coupon && modalType === "edit") {
          await updateCoupon(coupon.id, couponFormData);
          return success("Coupon successfully edited.");
        }

        // create
        await addCoupon(couponFormData);
        success("Coupon successfully created.");
      } catch (e) {
        error(e);
      }
    },
    [couponFormData, modalType, coupon, addCoupon, updateCoupon, error, success]
  );

  const isDisabledSubmitButton = useMemo(
    () =>
      !couponFormData.code ||
      !couponFormData.discount ||
      !couponFormData.description,

    [couponFormData]
  );

  return {
    couponFormData,
    isDisabledSubmitButton,
    handleChangeCouponsData,
    handleRemoveCoupon,
    onSubmit,
  };
}

export function useCouponsAdminFetch() {
  const { error } = useContext(errorContext);
  const { getCoupons } = useContext(couponContext);

  const [isCouponsLoading, setIsCouponsLoading] = useState(true);

  const couponsFetch = async () => {
    try {
      setIsCouponsLoading(true);

      await getCoupons();
    } catch (err) {
      error(err);
    } finally {
      setIsCouponsLoading(false);
    }
  };

  useEffect(() => {
    couponsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isCouponsLoading };
}
