import React from "react";

// components
import Button from "../../../../components/button/Button";

// images
import present_src from "../../../../assets/images/present.png";

// icons
import { LogoIcon } from "../../../../assets/icons/LogoIcon";
import { ArrowRightIcon } from "../../../../assets/icons/ArrowRightIcon";

// styles
import styles from "./Discount.module.scss";

export function Discount() {
  return (
    <section className={styles.discountSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountInfo}>
          <div className={styles.logo}>
            <LogoIcon />
          </div>
          <h1>
            Get EXTRA <span>€5!</span>
          </h1>
          <div className={styles.inputWrapper}>
            <Button
              className={styles.button}
              width="auto"
              icon={<ArrowRightIcon color="#fff" />}
            >
              Get coupon
            </Button>
          </div>
        </div>
        <div className={styles.discountImage}>
          <img src={present_src} alt="present" />
        </div>
      </div>
    </section>
  );
}
