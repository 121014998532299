import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { booksContext } from "../../../context/books-provider/BooksProvider";
import { storageContext } from "../../../context/storage-provider/StorageProvider";

// consts
import { INIT_BOOK_ADMIN_FORM } from "./BooksAdminScreen.consts";

// types
import type {
  BookAdminFormType,
  BookType,
} from "../../../context/books-provider/BooksProvider.types";
import { ActionMeta } from "react-select";

export function useBookAdminForm(
  modalType?: "create" | "edit" | null,
  book?: BookType | null
) {
  const { error, success } = useContext(errorContext);
  const { addBook, updateBook, deleteBook } = useContext(booksContext);
  const { uploadFile, deleteFile } = useContext(storageContext);

  const pictureInputRef = useRef<any>(null);
  const bookInputRef = useRef<any>(null);

  const [bookAdminFormData, setBookAdminFormData] =
    useState<BookAdminFormType>(INIT_BOOK_ADMIN_FORM);

  useEffect(() => {
    if (book && modalType === "edit") {
      const {
        title,
        description,
        tags,
        category,
        author,
        price,
        discountPrice,
        pages,
        pictures,
        source,
        isPublished,
      } = book;

      setBookAdminFormData({
        title,
        description,
        tags,
        category,
        author,
        price,
        discountPrice,
        pages,
        pictures,
        source,
        isPublished,
      });
    } else {
      setBookAdminFormData(INIT_BOOK_ADMIN_FORM);
    }
  }, [book, modalType]);

  const handleChangeBookAdminData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      if (name === "discountPrice") {
        return setBookAdminFormData((prev) => ({
          ...prev,
          discountPrice: value ? value : null,
        }));
      }

      if (name === "isPublished") {
        return setBookAdminFormData((prev) => ({
          ...prev,
          isPublished: !prev.isPublished,
        }));
      }

      setBookAdminFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setBookAdminFormData, error]
  );

  const handleChangeSelectData = useCallback(
    (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      if (Array.isArray(newValue)) {
        const multiOptions = newValue as { label: string; value: string }[];

        return setBookAdminFormData((prev) => ({
          ...prev,
          [actionMeta.name || ""]: multiOptions.map(
            (multiOption) => multiOption.value
          ),
        }));
      }

      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setBookAdminFormData((prev) => ({
        ...prev,
        [actionMeta.name || ""]: option.value,
      }));
    },
    [setBookAdminFormData]
  );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const filString = reader.result as string;

        setBookAdminFormData((prev) => ({
          ...prev,
          pictures: [...prev.pictures, filString.split(",")?.[1]],
        }));
      }
    };

    reader.onerror = () => {
      error(
        "A problem has arisen with the file, check the file and its format."
      );
    };

    if (reader) {
      reader.readAsDataURL(file);
    }
    return null;
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: { "image/*": [] },
    });

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        // edit
        if (book && modalType === "edit") {
          await updateBook(book.id, bookAdminFormData);
          return success("Book successfully edited.");
        }

        // create
        await addBook(bookAdminFormData);
        success("Book successfully created.");
      } catch (e) {
        throw e;
      }
    },
    [bookAdminFormData, modalType, book, addBook, updateBook, success]
  );

  const handleRemoveBook = useCallback(
    async (bookId?: string) => {
      try {
        if (bookId) {
          await deleteBook(bookId);
          success("The book has been successfully deleted");
        }
      } catch (e) {
        throw e;
      }
    },
    [deleteBook, success]
  );

  const handleRemovePicture = (key: string) => {
    setBookAdminFormData((prev) => ({
      ...prev,
      pictures: prev.pictures.filter((picture) => picture !== key),
    }));
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = bookInputRef.current.files[0] as File;

      const source = await uploadFile(file);

      if (source) {
        setBookAdminFormData((prev) => ({
          ...prev,
          source: { ...source.file, createdAt: `${source.file.createdAt}` },
        }));
      }

      success("File was succsessfully uploaded");
    } catch (e) {
      error(e);
    }
  };

  const handleRemoveSource = async () => {
    if (bookAdminFormData.source?.key) {
      try {
        await deleteFile(bookAdminFormData.source.key);

        success("File was successfully deleted");
      } catch (e) {
        error(e);
      }
    }
  };

  const isDisabledSubmitButton = useMemo(
    () =>
      !bookAdminFormData.title ||
      !bookAdminFormData.description ||
      !bookAdminFormData.category ||
      !bookAdminFormData.author ||
      !bookAdminFormData.price ||
      !bookAdminFormData.pages,
    [bookAdminFormData]
  );

  const categoryBooksAdminOptions = useMemo(
    () => [
      { label: "Business", value: "business" },
      { label: "Management", value: "management" },
      { label: "Motivation", value: "motivation" },
      { label: "Self-realization", value: "self-realization" },
      { label: "Time management", value: "Time management" },
    ],
    []
  );

  const tagsBooksAdminOptions = useMemo(
    () => [
      { label: "Sponsored", value: "sponsored" },
      { label: "Authored", value: "authored" },
      { label: "Starred", value: "starred" },
    ],
    []
  );

  return {
    bookAdminFormData,
    pictureInputRef,
    bookInputRef,
    isDisabledSubmitButton,
    categoryBooksAdminOptions,
    tagsBooksAdminOptions,
    setBookAdminFormData,
    handleChangeBookAdminData,
    handleChangeSelectData,
    handleRemovePicture,
    handleRemoveBook,
    handleUpload,
    handleRemoveSource,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    onSubmit,
  };
}

export function useBooksAdminFetch() {
  const { error } = useContext(errorContext);
  const { getBooks } = useContext(booksContext);

  const [isBooksAdminLoading, setIsBooksAdminLoading] = useState(true);

  const [itemOffset, setItemOffset] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(10);

  const booksAdminFetch = async () => {
    try {
      setIsBooksAdminLoading(true);

      await getBooks(itemsAmount, itemOffset);
    } catch (err) {
      error(err);
    } finally {
      setIsBooksAdminLoading(false);
    }
  };

  useEffect(() => {
    booksAdminFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsAmount]);

  return {
    isBooksAdminLoading,
    itemsAmount,
    setItemsAmount,
    itemOffset,
    setItemOffset,
  };
}
